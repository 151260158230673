// React & Material UI Imports
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";

//Components
// import TransfersList from "../elements/TransportList";
// import TransportTable from "../elements/TransportTable";

//Import Helper Functions
import { toCurrency } from "../../utilities/HelperFunctions/helperFunctions";

//React Slick Carousel Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    rendezvousDropOffContainer: {
        maxWidth: "1000px",
        margin: "30px auto",
    },
    rendezvousDropOff: {
        display: "block",
        fontSize: "12px",
        position: "relative",
        marginTop: "1em",
    },
    pickUpDropOffTitle: {
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: 300,
        textTransform: "uppercase",
        marginTop: 0,
    },
    pickUpDropOffSubtitle: {
        margin: 0,
        fontWeight: 600,
        textTransform: "uppercase",
    },
    container: {
        maxWidth: "1000px",
        margin: "auto",
        textAlign: "left",
        padding: "0 20px 20px 20px",
    },
    sectionTitle: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        marginTop: 0,
        fontSize: "2rem",
    },
    title: {
        textAlign: "left",
        textTransform: "uppercase",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "24px",
        fontWeight: 300,
        margin: 0,
    },
    countryDetailsTitle: {
        textAlign: "left",
        textTransform: "uppercase",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "18px",
        fontWeight: 300,
        margin: 0,
    },
    text: {
        textAlign: "left",
        fontFamily: theme.typography.body1.fontFamily,
        marginBottom: 0,
        fontSize: "14px",
    },
    details: {
        border: "1px solid #494949",
        padding: "30px",
        margin: "30px 0",
        "& ul": {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: "16px",
        },
    },
    localInformationPanel: {
        boxShadow: "none",
    },
    guidePhoto: {
        objectFit: "cover",
        height: "100%",
        width: "100%",
    },
    guideName: {
        color: "#194666",
        margin: "0 0 0.5rem 0",
        textTransform: "uppercase",
        fontFamily: "Lora, serif",
        fontWeight: 300,
    },
    guideBio: {
        margin: "0",
        textAlign: "justify",
    },
}));

const lessThan30DaysToStart = (tripStartDate) => {
    let now = new Date();
    tripStartDate = new Date(tripStartDate);

    let differenceInTime = tripStartDate.getTime() - now.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays < 45) {
        return true;
    } else {
        return false;
    }
};

const removeFirstCharacter = (string, unwantedCharacter) => {
    //there is really no need for === check, since we use String's charAt()
    while (string.charAt(0) == unwantedCharacter) string = string.substring(1);
    //yourString now contains "test"
    return string;
};

//Run Component
export default function ExperienceDetailsContent(props) {
    const {
        urlToHyperlink,
        tripType,
        isBooked,
        experienceDetailsData,
        tripContent,
        departureContent,
        pickUpDropOff,
        itineraryCountryContent,
        itinerary,
        itineraryRelatedContent,
        itineraryHotelContent,
        isPrint,
        otpContent,
        isOtpComplete,
        dateDisplayOptions,
        tripStart,
        tripEnd,
    } = props;

    const isWithin30Days = lessThan30DaysToStart(departureContent?.departures[0]?.p15_startdate);

    const getBrRendezvousFarewell = (departureContent) => {
        let RendezvousFarewell;
        if (departureContent.departures) {
            RendezvousFarewell = { ...departureContent.departures[0] };
        } else {
            RendezvousFarewell = null;
        }
        if (
            //Check if all properties exists
            !RendezvousFarewell.br_pickupcity &&
            !RendezvousFarewell.br_pickuplocation &&
            !RendezvousFarewell.br_pickuptime &&
            !RendezvousFarewell.br_pickupdescription &&
            !RendezvousFarewell.br_dropoffcity &&
            !RendezvousFarewell.br_dropofflocation &&
            !RendezvousFarewell.br_dropofftime &&
            !RendezvousFarewell.br_dropoffdescription
        ) {
            return null;
        } else {
            return RendezvousFarewell;
        }
    };

    let rendezvousFarewell = getBrRendezvousFarewell(departureContent);

    //Pull In Styles
    const classes = useStyles();

    const renderSupportLevel = (supportLevel) => {
        let supportLevelText;
        switch (supportLevel) {
            case 100000000:
                supportLevelText = "24/7 Guided";
                break;
            case 100000001:
                supportLevelText = "Daytime Guided";
                break;
            case 100000002:
                supportLevelText = "Self-Guided";
        }
        return supportLevelText;
    };

    //buildRoute(CSVToArray(itinerary.routes[1].points, ";")[0]);

    const renderEquipmentSlides = () =>
        experienceDetailsData.equipmentList.map((equipment) => (
            <div className={classes.text}>
                <p
                    style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        marginBottom: 0,
                    }}
                >
                    {equipment.name} -{" "}
                    <a
                        target="_blank"
                        style={{ color: "#64a7ed" }}
                        href={`${process.env.REACT_APP_APP_ENDPOINT}/our-equipment`}
                    >
                        More Information
                    </a>
                </p>
                {/* <p style={{ margin: 0 }}>{equipment.subtitle}</p> */}
                <p>{equipment.description}</p>
                <div>
                    <img src={equipment.image} style={{ width: "80%", margin: "1rem auto 0" }} />
                </div>
            </div>
        ));

    const renderCountryInformation = () => {
        return (
            <>
                {itineraryCountryContent.map((countryContent) => {
                    return (
                        <div>
                            <Typography
                                className={classes.title}
                                style={{
                                    color: "#194666",
                                    // borderBottom: "1px solid #494949",
                                    maxWidth: "400px",
                                    margin: "auto",
                                    paddingBottom: "10px",
                                    textAlign: "center",
                                    fontSize: "24px",
                                }}
                            >
                                {countryContent.name}
                            </Typography>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Banking & Currency
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information
                                                        .banking,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Travel, Transportation And Getting Around
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information
                                                        .transport,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Food & Drink
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information
                                                        .cuisine,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Clothing & Dress Recommendations
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information.dress,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Climate & Weather
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information
                                                        .climate,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel className={classes.localInformationPanel}>
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.countryDetailsTitle}>
                                            Electricity & Plugs
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={classes.destinationDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent.travel_information
                                                        .electricity,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            {countryContent.travel_information.additional_info ? (
                                <div className={classes.details}>
                                    <ExpansionPanel className={classes.localInformationPanel}>
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.countryDetailsTitle}>
                                                General Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={classes.destinationDescription}
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent.travel_information
                                                            .additional_info,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.health ? (
                                <div className={classes.details}>
                                    <ExpansionPanel className={classes.localInformationPanel}>
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.countryDetailsTitle}>
                                                Health
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={classes.destinationDescription}
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent.travel_information
                                                            .health,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.visa ? (
                                <div className={classes.details}>
                                    <ExpansionPanel className={classes.localInformationPanel}>
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.countryDetailsTitle}>
                                                Entry Requirements
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={classes.destinationDescription}
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent.travel_information
                                                            .visa,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.safety ? (
                                <div className={classes.details}>
                                    <ExpansionPanel className={classes.localInformationPanel}>
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.countryDetailsTitle}>
                                                Safety
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={classes.destinationDescription}
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent.travel_information
                                                            .safety,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    //Render
    return (
        <div
            className={classes.container + " experience-details"}
            style={isPrint ? { padding: 0, margin: "0 auto" } : {}}
        >
            <div style={{ padding: isPrint ? 0 : "2rem 0 0 0" }}>
                <h3
                    className={classes.sectionTitle}
                    style={{
                        color: "#194666",
                        borderBottom: "1px solid #494949",
                        maxWidth: "85%",
                        margin: "0 auto",
                        paddingBottom: isPrint ? "0" : "2rem",
                        textAlign: "center",
                        fontSize: isPrint ? "1.2rem" : "",
                        pageBreakBefore: "always",
                    }}
                    id="trip_details"
                >
                    Details
                </h3>
                <p style={{ textAlign: "center" }}>
                    <em>
                        *As we fine-tune and finalize your trip, please note that your itinerary
                        maybe be subject to changes.
                    </em>
                </p>
            </div>
            {isBooked && otpContent?.guides && isWithin30Days ? (
                <div className={classes.details} style={{ pageBreakAfter: "always" }}>
                    <h3
                        className={classes.title}
                        style={{
                            textTransform: "uppercase",
                            margin: "0 0 20px 0",
                        }}
                    >
                        {otpContent.guides.length > 1 ? "Your Guides" : "Your Guide"}
                    </h3>
                    <div>
                        <Grid container spacing={3}>
                            {otpContent.guides.map((guide) => {
                                return (
                                    <>
                                        {isPrint ? (
                                            <></>
                                        ) : (
                                            <Grid item xs={isPrint ? 4 : 12} sm={5} md={4}>
                                                <img
                                                    className={classes.guidePhoto}
                                                    src={guide.photo}
                                                    alt=""
                                                />
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={isPrint ? 12 : 12}
                                            sm={isPrint ? 12 : 7}
                                            md={isPrint ? 12 : 8}
                                        >
                                            <h2 className={classes.guideName}>
                                                {guide.preferredName}
                                            </h2>
                                            <p className={classes.guideBio}>{guide.bio}</p>
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {(tripType == "Small Group" || tripType == "Self-guided") &&
            !isBooked &&
            departureContent.departures.length != 0 ? (
                //Show Peak15 Price Data
                <div className={classes.details}>
                    <h3
                        className={classes.title}
                        style={{
                            textTransform: "uppercase",
                            marginBottom: "0.5rem",
                        }}
                    >
                        Price
                    </h3>
                    <div className={classes.text}>
                        <p style={{ margin: 0, fontSize: "1.4rem" }}>
                            <strong>
                                Per person from{" "}
                                {
                                    departureContent?.departures?.sort((a, b) => {
                                        let priceA = parseFloat(
                                            a.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                        );
                                        let priceB = parseFloat(
                                            b.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                        );
                                        return priceA - priceB;
                                    })?.[0]?.p15_fromprice
                                }{" "}
                                USD
                            </strong>
                        </p>
                        <p style={{ margin: 0 }}>
                            <em>Based on double occupancy</em>
                        </p>
                        {departureContent.departures?.sort((a, b) => {
                            let priceA = parseFloat(a.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            let priceB = parseFloat(b.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            return priceA - priceB;
                        })?.[0].cms_departure_single_supplement ? (
                            <p style={{ margin: 0 }}>
                                <em>
                                    Single Supplement:{" "}
                                    {
                                        departureContent.departures?.sort((a, b) => {
                                            let priceA = parseFloat(
                                                a.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                            );
                                            let priceB = parseFloat(
                                                b.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                            );
                                            return priceA - priceB;
                                        })?.[0].cms_departure_single_supplement
                                    }{" "}
                                    USD
                                </em>
                            </p>
                        ) : (
                            <></>
                        )}
                        {departureContent.departures?.sort((a, b) => {
                            let priceA = parseFloat(a.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            let priceB = parseFloat(b.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            return priceA - priceB;
                        })?.[0].p15_pricedescription ? (
                            //Show Wetu Price Data
                            <p>
                                {
                                    departureContent.departures?.sort((a, b) => {
                                        let priceA = parseFloat(
                                            a.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                        );
                                        let priceB = parseFloat(
                                            b.p15_fromprice.replace(/[^0-9.-]+/g, "")
                                        );
                                        return priceA - priceB;
                                    })?.[0].p15_pricedescription
                                }
                            </p>
                        ) : (
                            //Show Nothing
                            <></>
                        )}
                        {departureContent.departures?.sort((a, b) => {
                            let priceA = parseFloat(a.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            let priceB = parseFloat(b.p15_fromprice.replace(/[^0-9.-]+/g, ""));
                            return priceA - priceB;
                        })?.[0].br_suiteupgradesavailable ? (
                            <p>Upgrades Available</p>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : experienceDetailsData.price ? (
                <div className={classes.details}>
                    <h3 className={classes.title} style={{ textTransform: "uppercase" }}>
                        Price
                    </h3>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: urlToHyperlink(experienceDetailsData.price),
                        }}
                    ></div>
                </div>
            ) : (
                <></>
            )}

            {/* {tripType === "Small Group" ? (
                isBooked ? (
                    <></>
                ) : (
                    <div className={classes.details}>
                        <h3
                            className={classes.title}
                            style={{ textTransform: "uppercase" }}
                        >
                            Price
                        </h3>
                        <div className={classes.text}>
                            <p>
                                Per person from{" "}
                                {departureContent.departures[0]?.p15_fromprice}
                            </p>
                        </div>
                    </div>
                )
            ) : experienceDetailsData.price ? (
                <div className={classes.details}>
                    <h3
                        className={classes.title}
                        style={{ textTransform: "uppercase" }}
                    >
                        Price
                    </h3>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: experienceDetailsData.price,
                        }}
                    ></div>
                </div>
            ) : (
                <></>
            )} */}

            {experienceDetailsData.inclusions && experienceDetailsData.exclusions ? (
                <div className={classes.details}>
                    <h3 className={classes.title} style={{ textTransform: "uppercase" }}>
                        The Fine Print
                    </h3>
                    <div>
                        <Grid container>
                            <Grid item xs={isPrint ? 6 : 12} sm={6}>
                                <div>
                                    <h3
                                        style={{
                                            marginBottom: 0,
                                            textTransform: "uppercase",
                                            fontSize: "16px",
                                            fontFamily: "Muli, sans-serif",
                                            lineHeight: 1.5,
                                            letterSpacing: "0.00938em",
                                        }}
                                    >
                                        Trip Inclusions:
                                    </h3>
                                    <div
                                        style={{
                                            textAlign: "left",
                                            fontSize: "12px",
                                            marginTop: 0,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: urlToHyperlink(
                                                experienceDetailsData.inclusions
                                            ),
                                        }}
                                    ></div>
                                </div>
                            </Grid>
                            <Grid item xs={isPrint ? 6 : 12} sm={6}>
                                <div>
                                    <h3
                                        style={{
                                            marginBottom: 0,
                                            textTransform: "uppercase",
                                            fontSize: "16px",
                                            fontFamily: "Muli, sans-serif",
                                            lineHeight: 1.5,
                                            letterSpacing: "0.00938em",
                                        }}
                                    >
                                        Trip Exclusions:
                                    </h3>
                                    <div
                                        style={{
                                            textAlign: "left",
                                            fontSize: "12px",
                                            marginTop: 0,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: urlToHyperlink(
                                                experienceDetailsData.exclusions
                                            ),
                                        }}
                                    ></div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div style={{ pageBreakAfter: "always" }}></div>
            {/* Pick Up & Drop Off */}
            <>
                {pickUpDropOff ? (
                    <div className={classes.rendezvousDropOffContainer}>
                        <Grid container spacing={0} style={{ border: "1px solid #494949" }}>
                            <Grid
                                item
                                xs={isPrint ? 6 : 12}
                                sm={6}
                                style={{
                                    padding: "60px",
                                    position: "relative",
                                    textAlign: "center",
                                }}
                            >
                                <h3
                                    className={classes.title}
                                    style={{
                                        textAlign: "left",
                                        color: "#194666",
                                    }}
                                >
                                    Trip Start
                                </h3>
                                <div className={classes.rendezvousDropOff}>
                                    <p className={classes.pickUpDropOffSubtitle}>City:</p>
                                    <p style={{ marginTop: 0 }}>{pickUpDropOff.pickUpCity}</p>
                                </div>
                                <div className={classes.rendezvousDropOff}>
                                    <p className={classes.pickUpDropOffSubtitle}>Location:</p>
                                    <p style={{ marginTop: 0 }}>{pickUpDropOff.pickUpLocation}</p>
                                </div>
                                {tripStart ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Date:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {new Intl.DateTimeFormat("en-US", {
                                                weekday: "long",
                                                ...dateDisplayOptions,
                                            }).format(tripStart)}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {!pickUpDropOff.pickUpTime &&
                                pickUpDropOff.pickUpTimeSlot == "None" ? (
                                    <></>
                                ) : (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Time:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {!pickUpDropOff.pickUpTime ? (
                                                pickUpDropOff.pickUpTimeSlot == "None" ? (
                                                    <></>
                                                ) : (
                                                    pickUpDropOff.pickUpTimeSlot
                                                )
                                            ) : (
                                                pickUpDropOff.pickUpTime
                                            )}
                                        </p>
                                    </div>
                                )}
                                <div
                                    style={{
                                        textAlign: "justify",
                                        fontSize: "16px",
                                        fontFamily: "'Muli', sans-serif",
                                    }}
                                >
                                    {pickUpDropOff.pickUpDescription}
                                </div>
                                <div
                                    className={"divider"}
                                    style={{
                                        borderRight: "1px solid #494949",
                                        height: "300px",
                                        position: "absolute",
                                        top: "50%",
                                        right: 0,
                                        transform: "translateY(-50%)",
                                    }}
                                ></div>
                            </Grid>
                            <Grid item xs={isPrint ? 6 : 12} sm={6} style={{ padding: "60px" }}>
                                <h3
                                    className={classes.title}
                                    style={{
                                        textAlign: "left",
                                        color: "#194666",
                                    }}
                                >
                                    Trip Finish
                                </h3>
                                <div className={classes.rendezvousDropOff}>
                                    <p className={classes.pickUpDropOffSubtitle}>City:</p>
                                    <p style={{ marginTop: 0 }}>{pickUpDropOff.dropOffCity}</p>
                                </div>
                                <div className={classes.rendezvousDropOff}>
                                    <p className={classes.pickUpDropOffSubtitle}>Location:</p>
                                    <p style={{ marginTop: 0 }}>{pickUpDropOff.dropOffLocation}</p>
                                </div>
                                {tripEnd ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Date:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {new Intl.DateTimeFormat("en-US", {
                                                weekday: "long",
                                                ...dateDisplayOptions,
                                            }).format(tripEnd)}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {!pickUpDropOff.dropOffTime &&
                                pickUpDropOff.dropOffTimeSlot == "None" ? (
                                    <></>
                                ) : (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Time:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {!pickUpDropOff.dropOffTime ? (
                                                pickUpDropOff.dropOffTimeSlot == "None" ? (
                                                    <></>
                                                ) : (
                                                    pickUpDropOff.dropOffTimeSlot
                                                )
                                            ) : (
                                                pickUpDropOff.dropOffTime
                                            )}
                                        </p>
                                    </div>
                                )}
                                <div
                                    style={{
                                        textAlign: "justify",
                                        fontSize: "16px",
                                        fontFamily: "'Muli', sans-serif",
                                    }}
                                >
                                    {pickUpDropOff.dropOffDescription}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <></>
                )}
            </>
            {/* B&R Rendexvous & Farewell */}
            <>
                {tripType == "Small Group" && rendezvousFarewell ? (
                    <div className={classes.rendezvousDropOffContainer}>
                        <Grid container spacing={0} style={{ border: "1px solid #494949" }}>
                            <Grid
                                item
                                xs={isPrint ? 6 : 12}
                                sm={6}
                                style={{
                                    padding: "60px",
                                    position: "relative",
                                    textAlign: "center",
                                }}
                            >
                                <h3
                                    className={classes.title}
                                    style={{
                                        textAlign: "left",
                                        color: "#194666",
                                    }}
                                >
                                    B&R Rendezvous
                                </h3>
                                {rendezvousFarewell.br_pickupcity ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>City:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_pickupcity}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_pickuplocation ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Location:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_pickuplocation}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.p15_startdate && isBooked ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Date:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {new Intl.DateTimeFormat("en-US", {
                                                weekday: "long",
                                                ...dateDisplayOptions,
                                            }).format(new Date(rendezvousFarewell.p15_startdate))}
                                            {/* {
                                                rendezvousFarewell.p15_startdate.split(
                                                    "T"
                                                )[0]
                                            } */}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_pickuptime ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Time:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_pickuptime}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_pickupdescription ? (
                                    <div
                                        style={{
                                            textAlign: "justify",
                                            fontSize: "16px",
                                            fontFamily: "'Muli', sans-serif",
                                        }}
                                    >
                                        {rendezvousFarewell.br_pickupdescription}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div
                                    className={"divider"}
                                    style={{
                                        borderRight: "1px solid #494949",
                                        height: "300px",
                                        position: "absolute",
                                        top: "50%",
                                        right: 0,
                                        transform: "translateY(-50%)",
                                    }}
                                ></div>
                            </Grid>
                            <Grid item xs={isPrint ? 6 : 12} sm={6} style={{ padding: "60px" }}>
                                <h3
                                    className={classes.title}
                                    style={{
                                        textAlign: "left",
                                        color: "#194666",
                                    }}
                                >
                                    B&R Farewell
                                </h3>
                                {rendezvousFarewell.br_dropoffcity ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>City:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_dropoffcity}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_dropofflocation ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Location:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_dropofflocation}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.p15_enddate && isBooked ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Date:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {new Intl.DateTimeFormat("en-US", {
                                                weekday: "long",
                                                ...dateDisplayOptions,
                                            }).format(new Date(rendezvousFarewell.p15_enddate))}
                                            {/* {
                                                rendezvousFarewell.p15_enddate.split(
                                                    "T"
                                                )[0]
                                            } */}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_dropofftime ? (
                                    <div className={classes.rendezvousDropOff}>
                                        <p className={classes.pickUpDropOffSubtitle}>Time:</p>
                                        <p style={{ marginTop: 0 }}>
                                            {rendezvousFarewell.br_dropofftime}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {rendezvousFarewell.br_dropoffdescription ? (
                                    <div
                                        style={{
                                            textAlign: "justify",
                                            fontSize: "16px",
                                            fontFamily: "'Muli', sans-serif",
                                        }}
                                    >
                                        {rendezvousFarewell.br_dropoffdescription}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            {isBooked ? (
                                <></>
                            ) : (
                                <Grid item xs={12} style={{ padding: "0 60px 10px" }}>
                                    <p>
                                        <InfoIcon style={{ fontSize: "16px" }} /> Get in touch about
                                        booking your pre-trip night at the rendezvous hotel.
                                    </p>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                ) : null}
            </>
            {/*Transfer and Flights*/}
            {/* <TransfersList
                itinerary={itinerary}
                itineraryRelatedContent={itineraryRelatedContent}
                isPrint={isPrint}
            />
            <TransportTable
                itinerary={itinerary}
                itineraryRelatedContent={itineraryRelatedContent}
                isPrint={isPrint}
            /> */}
            <>
                {isBooked ? (
                    <div className={classes.details}>
                        {/* <h3
                            className={classes.title}
                            style={{ textTransform: "uppercase" }}
                        >
                            Trip Contacts
                        </h3> */}
                        <h3 className={classes.countryDetailsTitle} style={{ marginTop: "1.5rem" }}>
                            Emergency Contacts:
                        </h3>
                        <ul>
                            {departureContent?.departures[0]?.br_emergencycontactname &&
                            departureContent?.departures[0]?.br_emergencycontactnumber ? (
                                <li style={{ listStyleType: "none" }}>
                                    {departureContent.departures[0].br_emergencycontactname} |{" "}
                                    <a
                                        href={`tel:${departureContent.departures[0].br_emergencycontactnumber}`}
                                    >
                                        {departureContent.departures[0].br_emergencycontactnumber}
                                    </a>
                                </li>
                            ) : (
                                <></>
                            )}
                            <li style={{ listStyleType: "none" }}>
                                B&R Toronto Office (24-Hour) |{" "}
                                <a href="tel:+1 800 678 1147">+1 800 678 1147</a>
                            </li>
                        </ul>
                        <>
                            {itinerary.contacts ? (
                                <>
                                    <h3
                                        className={classes.countryDetailsTitle}
                                        style={{ marginTop: "1.5rem" }}
                                    >
                                        Trip Contacts:
                                    </h3>
                                    <ul>
                                        {itinerary.contacts.map((contact) => (
                                            <li
                                                style={{
                                                    listStyleType: "none",
                                                }}
                                            >
                                                {removeFirstCharacter(contact.company, "*")}
                                                {!contact.contact_person ||
                                                contact.contact_person == "" ? (
                                                    <></>
                                                ) : (
                                                    <>, {contact.contact_person}</>
                                                )}{" "}
                                                |{" "}
                                                <a href={`tel:${contact.telephone}`}>
                                                    {contact.telephone}
                                                </a>
                                                {!contact.alternative_telephone ||
                                                contact.alternative_telephone == "" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <a
                                                            href={`tel:${contact.alternative_telephone}`}
                                                        >
                                                            {contact.alternative_telephone}
                                                        </a>
                                                    </>
                                                )}
                                                {!contact.email || contact.email == "" ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <a href={`mailto:${contact.email}`}>
                                                            {contact.email}
                                                        </a>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    </div>
                ) : (
                    <></>
                )}
            </>
            {itinerary.documents && !isPrint ? (
                <div className={classes.details}>
                    <h3 className={classes.title} style={{ textTransform: "uppercase" }}>
                        Documents
                    </h3>
                    <ul>
                        {itinerary.documents.map((document) => (
                            <>
                                {!document.hidden ? (
                                    <a
                                        href={`https://wetu.com/Resources/Itineraries/${itinerary.identifier}/${document.element}`}
                                        target="_blank"
                                    >
                                        <li>{document.name}</li>
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </>
                        ))}
                    </ul>
                </div>
            ) : null}
            {isBooked && departureContent?.departures[0]?.br_tippingguidelines ? (
                <div className={classes.details}>
                    <h3 className={classes.title} style={{ textTransform: "uppercase" }}>
                        Tipping Guidelines
                    </h3>
                    <Typography>{departureContent.departures[0].br_tippingguidelines}</Typography>
                    {/* <ul>
                        {itinerary.documents.map((document) => (
                            <>
                                {!document.hidden ? (
                                    <a
                                        href={`https://wetu.com/Resources/Itineraries/${itinerary.identifier}/${document.element}`}
                                        target="_blank"
                                    >
                                        <li>{document.name}</li>
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </>
                        ))} 
                    </ul>*/}
                </div>
            ) : null}

            {departureContent.departures.length == 0 ? null : departureContent.departures[0]
                  .br_itineraryactivityleveldescription == null ? (
                departureContent.departures.br_itineraryactivityleveldescription == null ? null : (
                    <div className={classes.details}>
                        <h3 className={classes.title}>
                            Activity Level:{" "}
                            {tripContent.trip ? tripContent.trip.br_activitylevel : ""}
                        </h3>
                        <p className={classes.text}>
                            {departureContent.departures.length > 0
                                ? departureContent.departures[0]
                                      .br_itineraryactivityleveldescription
                                : ""}
                        </p>
                    </div>
                )
            ) : (
                <div className={classes.details}>
                    <h3 className={classes.title}>
                        Activity Level: {tripContent.trip ? tripContent.trip.br_activitylevel : ""}
                    </h3>
                    <p className={classes.text}>
                        {departureContent.departures.length > 0
                            ? departureContent.departures[0].br_itineraryactivityleveldescription
                            : ""}
                    </p>
                </div>
            )}

            {isBooked ? (
                // <>
                //     {experienceDetailsData.equipmentList.length > 0 ? (
                //         <div className={classes.details + " equipment-slides"}>
                //             <h3 className={classes.title}>
                //                 Your B&R Equipment
                //             </h3>
                //             <Slider
                //                 dots={true}
                //                 autoplay={true}
                //                 autoplaySpeed={5000}
                //                 arrows={false}
                //             >
                //                 {renderEquipmentSlides()}
                //             </Slider>{" "}
                //         </div>
                //     ) : (
                <></>
            ) : //     )}
            // </>
            tripType == "Bespoke" ? (
                <></>
            ) : (
                <>
                    {experienceDetailsData.equipmentList.length > 0 && !isPrint ? (
                        <div className={classes.details + " equipment-slides"}>
                            <h3 className={classes.title}>Trip Equipment</h3>
                            <Slider dots={true} autoplay={true} autoplaySpeed={5000} arrows={false}>
                                {renderEquipmentSlides()}
                            </Slider>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
            {/* {isPrint ? <></> : renderCountryInformation()} */}
            {isPrint ? (
                <></>
            ) : (
                <div className={classes.details}>
                    <h3 className={classes.title} style={{ textTransform: "uppercase" }}>
                        Balance Your Flights
                    </h3>
                    <div className={classes.text}>
                        <p>
                            As part of B&R's commitment to carbon management we are dedicated to
                            measuring and reducing our greenhouse gas emissions across the B&R
                            ecosystem. However, we do not include your international flights in our
                            reporting matrix. For this reason, and after extensive research, we've
                            decided to partner with{" "}
                            <a href="https://butterfield.c-level.earth/Flight" target="_blank">
                                C-Level
                            </a>{" "}
                            to provide our travellers with a carbon balancing program.
                        </p>
                        <p>
                            We recognize offsets are by no means a cure for global CO2 emissions,
                            but they do account for one piece of the complex puzzle.
                        </p>
                        <p>
                            <a href="https://butterfield.c-level.earth/Flight" target="_blank">
                                CLICK HERE
                            </a>{" "}
                            to balance your flights!
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

ExperienceDetailsContent.propTypes = {
    tripType: PropTypes.string.isRequired,
    isBooked: PropTypes.bool.isRequired,
    experienceDetailsData: PropTypes.object.isRequired,
    tripContent: PropTypes.object.isRequired,
    departureContent: PropTypes.object.isRequired,
    pickUpDropOff: PropTypes.object.isRequired,
    itinerary: PropTypes.object.isRequired,
    itineraryHotelContent: PropTypes.object.isRequired,
};
